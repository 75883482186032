import * as React from 'react'
import Layout from '../../templates/layout'
import SloganPage from '../../components/molecules/SloganPage'
import Seo from '../../helpers/seo'
import HeaderContentSolution from '../../components/organisms/HeaderContentSolution'
import ContentSolution from '../../components/organisms/ContentSolution'
import CardsInformation from '../../components/molecules/CardsInformation'
import projectsJson from './projects.json'
import ContactForm from '../../components/organisms/ContactForm'
import CasesRelational from '../../components/organisms/CasesRelational'
import { assignIDToSolutionsJson } from '../../helpers/assignIDToSolutionsJson'

const ServicesWebProjects = () => {
  const { app, web, systems, ecommerce, integrations, relational } =
    assignIDToSolutionsJson(projectsJson)

  const cardContent = [
    {
      id: 1,
      heading: 'Desenvolvimento Web_',
      content: () => {
        return web.map((data) => {
          return (
            <ContentSolution
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
    {
      id: 2,
      heading: 'Sistemas_',
      content: () => {
        return systems.map((data) => {
          return (
            <ContentSolution
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'orange-light',
      selected: 'close',
    },
    {
      id: 3,
      heading: 'Aplicativos Mobile_',
      content: () => {
        return app.map((data) => {
          return (
            <ContentSolution
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
    {
      id: 4,
      heading: 'E-commerce_',
      content: () => {
        return ecommerce.map((data) => {
          return (
            <ContentSolution
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'orange-light',
      selected: 'close',
    },
    {
      id: 5,
      heading: 'Integrações_',
      content: () => {
        return integrations.map((data) => {
          return (
            <ContentSolution
              title={data.title}
              description={data.description}
              key={data.id}
            />
          )
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
  ]

  return (
    <Layout page="ServicesWebProjects">
      <SloganPage title={'Projetos'} type={'projects'} />
      <HeaderContentSolution
        icon={'project'}
        description={`<p>Na era da Transformação Digital, a inovação, a qualidade e a customização são chaves para se destacar.
        Nosso conjunto de serviços de projetos é desenhado para atender a essa necessidade, oferecendo soluções
        eficazes em desenvolvimento web, sistemas personalizados, aplicativos móveis, e-commerce e
        integrações. Cada projeto é uma oportunidade para transformar ideias em realidades, refletindo a
        identidade e os objetivos do seu negócio.</p>`}
      />
      <CardsInformation cardContent={cardContent} />
      <ContactForm />
      <CasesRelational relationalData={relational} currentCategory="Projetos" />
    </Layout>
  )
}

export const Head = () => <Seo title="Serviços" />

export default ServicesWebProjects
